import React, { useEffect, useState } from "react"
import { Header } from "../core"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { getFormatedBillingCycle } from "../../utils/functions"
import { Swiper, SwiperSlide } from "swiper/react"
import useWindowDimensions from "../../hooks/useWindowDimensionsWeb"
import Transaction_v3 from "../core/Transaction_v3"
import { BottomSheet } from "react-spring-bottom-sheet"
import FilterByDate from "../AllTransactions/v3/filterByDateV3"
import moment from "moment"
import { customDateRangeFilterFields } from "../../utils/enums"
import AccountService from "../../services/AccountService"
import NewUserSpends from "./newUserSpends"
import SpendAnalyticsChart from "./charts/spendAnalyticsChart"
import CategorySplitHeader from "./categorySplit/categorySplitHeader"
import DateFilterHeader from "./categorySplit/dateFilterHeader"
import SingleCategoryCard from "./categorySplit/singleCategoryCard"
import AllCategoriesInfoHeader from "./categorySplit/allCategoryInfoHeader"
import ZeroSpends from "./emptyStates/zeroSpends"
import AllCategoriesVisualization from "./categorySplit/AllCategoriesVisualization"
import CategoryCarouselCard from "./categorySplit/categoryCarouselCard"
import CategoryFilterBottomSheet from "./CategoryFilterBottomSheet"
import { useTranslation } from "react-i18next"
import {
  DurationDateType,
  FilterBottomSheetTabs,
  SpendTransactionDateIntervalTypes,
  TransactionDateTypes,
} from "../../utils/constants"
import CubeAppLoader from "../core/InAppLoader"

const SpendAnalytics = () => {
  const { t } = useTranslation()
  const user = useSelector(state => state.user)
  const screen = useSelector(state => state.screen)
  const navigate = useNavigate()
  const configuredGroups = screen.analyzeSpends.spendCategory
  const lastStatement = user.summary.lastStatement
  const latestTransactions = user?.summary?.latestTransactions

  const [chartOptions, setChartOptions] = useState()
  const [isCategoryBottomSheetOpen, setIsCategoryBottomSheetOpen] =
    useState(false)
  const [billingCycles, setBillingCycles] = useState([])
  const [selectedCycle, setSelectedCycle] = useState()
  const [customDateRange, setCustomDateRange] = useState()
  const [invalidCustomDateRange, setInvalidCustomDateRange] = useState()
  const [isCustomDateRangeSelected, setIsCustomDateRangeSelected] =
    useState(false)
  const [filteredCycle, setFilteredCycle] = useState()
  const [isDateBottomSheetOpen, setIsDateBottomSheetOpen] = useState()
  const [dateType, setDateType] = useState(FilterBottomSheetTabs.DURATION)
  const [activeCategoryIndex, setActiveCategoryIndex] = useState(0)

  const windowDimension = useWindowDimensions()

  const [dateObject, setDateObject] = useState({
    type: SpendTransactionDateIntervalTypes.RANGE,
    dateType: TransactionDateTypes.TRANSACTION_DATE,
    startDate: null,
    endDate: null,
  })

  const [spendGroups, setSpendGroups] = useState()
  const [dateInterval, setDateInterval] = useState("MONTH")
  const [group, setGroup] = useState()
  const [selectedGroup, setSelectedGroup] = useState()
  const [transactions, setTransactions] = useState()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!selectedCycle || !dateType) return
    setDateObject({
      type:
        dateType === FilterBottomSheetTabs.DURATION
          ? SpendTransactionDateIntervalTypes.RANGE
          : SpendTransactionDateIntervalTypes.BILLNG,
      dateType: TransactionDateTypes.TRANSACTION_DATE,
      startDate: filteredCycle?.from,
      endDate: filteredCycle?.to,
    })
  }, [filteredCycle])

  const getSpendGroups = async () => {
    try {
      const response = await AccountService.getSpendGroups(user?.account?.id, {
        period: {
          type: dateObject?.type,
          dateType: dateObject?.dateType,
          offset: 12,
          startDate: dateObject?.startDate,
          endDate: dateObject?.endDate,
        },
      })
      return response
    } catch (error) {
      console.log(error)
    }
  }

  const getBillingCycles = async () => {
    const lastStatementToDate = user.summary.lastStatement.toDate
    if (!lastStatementToDate) return
    try {
      let toDate = new Date(lastStatementToDate)
      let fromDate = new Date(toDate.getTime() - 210 * 24 * 60 * 60 * 1000)
      let finalToDate = moment(lastStatementToDate).format("YYYY-MM-DD")
      let finalFromDate = moment(fromDate).format("YYYY-MM-DD")

      const statementsResponse = await AccountService.getBillingCycles({
        accountId: user?.account?.id,
        from: finalFromDate,
        to: finalToDate,
      })
      if (statementsResponse.data.success) {
        setBillingCycles(prev => [
          ...prev,
          ...statementsResponse.data.data.statements,
        ])
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    setDateObject({
      ...dateObject,
      startDate: moment().startOf("year").format("YYYY-MM-DD"),
      endDate: moment().endOf("year").format("YYYY-MM-DD"),
    })
  }, [])

  useEffect(() => {
    const formattedBillingCycle = getFormatedBillingCycle(
      lastStatement.fromDate,
      lastStatement.toDate,
    )
    setSelectedCycle({
      label: formattedBillingCycle,
      id: lastStatement.id,
      index: 0,
    })
    setFilteredCycle({ label: formattedBillingCycle })
    getBillingCycles()
  }, [])

  useEffect(() => {
    if (billingCycles.length === 0) return
    if (!dateObject?.startDate || !dateObject?.endDate) {
      setDateObject({
        type:
          dateType === FilterBottomSheetTabs.DURATION
            ? SpendTransactionDateIntervalTypes.RANGE
            : SpendTransactionDateIntervalTypes.BILLNG,
        dateType: TransactionDateTypes.TRANSACTION_DATE,
        startDate: moment(billingCycles[0].fromDate).format("YYYY-MM-DD"),
        endDate: moment(billingCycles[0].toDate).format("YYYY-MM-DD"),
      })
    } else {
      if (!filteredCycle?.from || !filteredCycle?.to) return
      setDateObject({
        type:
          dateType === FilterBottomSheetTabs.DURATION
            ? SpendTransactionDateIntervalTypes.RANGE
            : SpendTransactionDateIntervalTypes.BILLNG,
        dateType: TransactionDateTypes.TRANSACTION_DATE,
        startDate: filteredCycle?.from,
        endDate: filteredCycle?.to,
      })
    }
  }, [billingCycles, filteredCycle, dateInterval])

  useEffect(() => {
    if (!dateObject?.startDate || !dateObject?.endDate) return
    ;(async function () {
      setIsLoading(true)
      try {
        const response = await getSpendGroups()
        if (response.data?.success) {
          setSpendGroups(response.data)
          setTransactions(response.data.data.topTransactions)
          if (!selectedGroup)
            handleSelectGroup(response.data.data.spendGroups[0])
          setGroup(response.data.data.spendGroups[0])
        }
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoading(false)
      }
    })()
  }, [dateObject?.startDate, dateObject?.endDate])

  const handleCategoryFilterClick = () =>
    setIsCategoryBottomSheetOpen(prev => !prev)
  const handleDateSelect = (label, from, to) => {
    setInvalidCustomDateRange({
      invalidRange: false,
      durationExceeded: false,
      invalidField: null,
    })
    setCustomDateRange({
      from: null,
      to: null,
    })
    if (label === DurationDateType.CUSTOM_RANGE)
      setIsCustomDateRangeSelected(true)
    else setIsCustomDateRangeSelected(false)
    setSelectedCycle({
      label: label,
      from: moment(from).format("YYYY-MM-DD"),
      to: moment(to).format("YYYY-MM-DD"),
    })
  }
  const handleApplyFilter = () => {
    if (
      isCustomDateRangeSelected &&
      customDateRange.from?.length > 0 &&
      customDateRange.to?.length > 0
    ) {
      const differenceInDays = moment(customDateRange.to, "DD/MM/YYYY").diff(
        moment(customDateRange.from, "DD/MM/YYYY"),
        "days",
      )

      if (differenceInDays > 90) {
        setInvalidCustomDateRange({
          invalidRange: false,
          durationExceeded: true,
          invalidField: null,
        })
        return
      }

      if (
        !moment(customDateRange.from, "DD/MM/YYYY", true).isValid() ||
        customDateRange.from?.length !== 10 ||
        moment(customDateRange.from, "DD/MM/YYYY").isAfter(
          new Date(),
          "DD/MM/YYYY",
        )
      ) {
        setInvalidCustomDateRange({
          invalidRange: true,
          durationExceeded: false,
          invalidField: customDateRangeFilterFields.FROM,
        })
        return
      }

      if (
        !moment(customDateRange.to, "DD/MM/YYYY", true).isValid() ||
        customDateRange.to?.length !== 10 ||
        moment(customDateRange.to, "DD/MM/YYYY").isBefore(
          user.account.dateCreated,
          "DD/MM/YYYY",
        )
      ) {
        setInvalidCustomDateRange({
          invalidRange: true,
          durationExceeded: false,
          invalidField: customDateRangeFilterFields.TO,
        })
        return
      }

      if (differenceInDays < 0) {
        setInvalidCustomDateRange({
          invalidRange: true,
          durationExceeded: false,
          invalidField: customDateRangeFilterFields.BOTH,
        })
        return
      }

      setFilteredCycle({
        label: DurationDateType.CUSTOM_RANGE,
        from: moment(customDateRange.from, "DD/MM/YYYY").format("YYYY-MM-DD"),
        to: moment(customDateRange.to, "DD/MM/YYYY").format("YYYY-MM-DD"),
      })
    } else {
      setFilteredCycle(selectedCycle)
    }

    setIsDateBottomSheetOpen(false)
  }

  const handleSelectGroup = group => {
    const spendGroupImage = configuredGroups?.find(
      item => item.groupingField === group?.groupId,
    )?.iconUrl
    setSelectedGroup({
      ...group,
      iconUrl: spendGroupImage,
    })
  }

  const handleSelectCategory = category => {
    setGroup(category)
  }

  const handleCategoryCta = () => {
    handleSelectGroup(group)
    setIsCategoryBottomSheetOpen(false)
  }

  return (
    <>
      <Header
        text={t("spendAnalytics.newUserSpends.spendAnalyticsText")}
        onBack={() => navigate(-1)}
      />
      {!latestTransactions?.length ? (
        <NewUserSpends />
      ) : isLoading ? (
        <div>
          <CubeAppLoader />
        </div>
      ) : (
        <div className='v3-spend-analytics-container'>
          <SpendAnalyticsChart
            handleCategoryFilter={handleCategoryFilterClick}
            selectedGroup={selectedGroup}
            chartOptions={chartOptions}
            spendGroups={spendGroups}
            setDateInterval={setDateInterval}
            dateInterval={dateInterval}
          />
          <div className='v3-spend-analytics-category-split-container'>
            <CategorySplitHeader
              setIsDateBottomSheetOpen={setIsDateBottomSheetOpen}
            />
            <DateFilterHeader dateObject={dateObject} />
            <div className='v3-spend-analytics-category-split-chart-2-container'>
              {spendGroups?.data?.spendGroups?.length === 1 ? (
                <SingleCategoryCard spendGroups={spendGroups} />
              ) : (
                <AllCategoriesInfoHeader spendGroups={spendGroups} />
              )}
              {spendGroups?.data?.topTransactions?.length === 0 ? (
                <ZeroSpends />
              ) : (
                <>
                  <AllCategoriesVisualization
                    spendGroups={spendGroups}
                    configuredGroups={configuredGroups}
                    activeCategoryIndex={activeCategoryIndex}
                    totalCategoryAmount={spendGroups?.data?.totalSpend}
                  />
                  <div className='v3-spend-analytics-category-split-chart-2-widget-info-text'>
                    {t("spendAnalytics.categorySplit.swipeForCategoryDetails")}
                  </div>
                  <div className='v3-spend-analytics-category-split-chart-2-widget-carousel-container'>
                    <Swiper
                      slidesPerView={1.2}
                      style={{
                        maxWidth:
                          windowDimension.width - 50 <= 450
                            ? windowDimension.width - 50
                            : 400,
                        padding: "0 10px",
                      }}
                      centeredSlides={true}
                      spaceBetween={-10}
                      onSlideChange={swiper => {
                        setActiveCategoryIndex(swiper.activeIndex)
                      }}
                    >
                      {spendGroups?.data?.spendGroups?.map((item, index) => {
                        return (
                          <SwiperSlide
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <CategoryCarouselCard
                              item={item}
                              activeCategoryIndex={activeCategoryIndex}
                              index={index}
                              spendGroups={spendGroups}
                              totalCategoryAmount={
                                spendGroups?.data?.totalSpend
                              }
                            />
                          </SwiperSlide>
                        )
                      })}
                    </Swiper>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className='v3-spend-analytics-top-transactions-container'>
            <div className='v3-spend-analytics-top-transactions-header'>
              {t("spendAnalytics.categorySplit.topThreeSpends")}
            </div>
            {transactions?.map(txn => {
              return <Transaction_v3 transaction={txn} />
            })}
            <div
              className='v3-spend-analytics-top-transaction-all-cta'
              onClick={() => navigate("/transactions")}
            >
              {t("spendAnalytics.categorySplit.viewAllTransactions")}
            </div>
          </div>
          <div
            className='v3-spend-analytics-bg-section-1'
            style={{ width: windowDimension.width }}
          ></div>
          {spendGroups?.data?.spendGroups?.length > 0 && (
            <div
              className='v3-spend-analytics-bg-section-2'
              style={{ width: windowDimension.width }}
            ></div>
          )}
        </div>
      )}
      <BottomSheet open={isCategoryBottomSheetOpen}>
        <CategoryFilterBottomSheet
          handleCategoryFilterClick={handleCategoryFilterClick}
          spendGroups={spendGroups}
          handleSelectCategory={handleSelectCategory}
          group={group}
          handleCategoryCta={handleCategoryCta}
          configuredGroups={configuredGroups}
        />
      </BottomSheet>
      <BottomSheet open={isDateBottomSheetOpen}>
        <FilterByDate
          billingCycles={billingCycles}
          selectedCycle={selectedCycle}
          setCustomDateRange={setCustomDateRange}
          customDateRange={customDateRange}
          handleDateSelect={handleDateSelect}
          handleApplyFilter={handleApplyFilter}
          setIsBottomSheetOpen={setIsDateBottomSheetOpen}
          invalidCustomDateRange={invalidCustomDateRange}
          setInvalidCustomDateRange={setInvalidCustomDateRange}
          setDateType={setDateType}
          dateType={dateType}
          isSameState={
            selectedCycle.from == filteredCycle.from &&
            selectedCycle.to == filteredCycle.to
          }
        />
      </BottomSheet>
    </>
  )
}

export default SpendAnalytics
