import axios from "axios"
import CryptoJs from "crypto-js"
import {
  encryptedPathsPatterns,
  publicPaths,
  unEncryptedPathsPatterns,
} from "../config/api"
import store from "../store/Store"
import { expireSession } from "../store/actions/Session"
import {
  consoleError,
  consoleExceptions,
  isResponseValid,
  isValidSuccessResponse,
} from "../utils/functions"
import { DEFAULT_TENANT } from "../utils/enums"
import encryptionUtil, { aesDecryptData } from "../utils/encryptionUtil"
import { publicKey } from "../utils/keys"

const urlsExemptFromValidation = [
  "/verify-challenge-otp",
  "/verify-challenge-email-otp",
  "/set-mpin",
  "/verify-challenge-mpin",
  "/verify-challenge-customer",
  "/cards/${cardId}/reset-pin",
  "/verify-otp",
  "/accounts/rewards/v2/summary",
]

const isEncryptedPath = path => {
  let isEncrypted = false

  encryptedPathsPatterns.forEach(pattern => {
    if (pattern.test(path)) isEncrypted = true
  })

  return isEncrypted
}

const isUnEncryptedPath = path => {
  let isUnEncryptedPath = false

  unEncryptedPathsPatterns.forEach(pattern => {
    if (pattern.test(path)) isUnEncryptedPath = true
  })
  return isUnEncryptedPath
}

const uuid = encryptionUtil.generateCekkey()
const encrypteduuid = encryptionUtil.rsaEncryptData(
  uuid,
  publicKey[process.env.REACT_APP_ENV],
)

const Service = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL + "/pwa",
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json, text/plain",
  },
  responseType: "json",
  validateStatus: function (status) {
    return true // Resolve for all status codes
  },
})

Service.interceptors.request.use(
  function (req) {
    const session = store.getState().session

    if (!publicPaths.includes(req.url)) {
      req.headers["Authorization"] = `Bearer ${session.deviceToken}`
      req.headers["tenantId"] =
        localStorage.getItem("tenantId") || DEFAULT_TENANT
    }

    if (isEncryptedPath(req.url) && req.data) {
      const key = CryptoJs.enc.Base64.parse(session.encryptionKey)
      const iv = CryptoJs.enc.Base64.parse(session.encryptionIv)
      const encryptedRequest = CryptoJs.AES.encrypt(
        JSON.stringify(req.data),
        key,
        { iv },
      ).toString()
      req.data = { encryptedRequest }
    }
    // wrapper encryption
    if (!isUnEncryptedPath(req.url)) {
      const sessionIsEncrypted = session.isPwaLoading === "true"
      const localIsEncrypted = localStorage.getItem("isPwaLoading") === "true"
      if (sessionIsEncrypted || localIsEncrypted) {
        if (req.method === "post") {
          const encryptedWrapperRequest = encryptionUtil.aesEncryptData(
            req.data,
            uuid,
          )
          req.data = encryptedWrapperRequest
        }
        req.headers["x-aes-key"] = encrypteduuid
        req.headers["Content-Type"] = "text/plain"
        req.responseType = "text"
      }
    }
    return req
  },
  function (error) {
    return Promise.reject(error)
  },
)

Service.interceptors.response.use(
  async function (res) {
    if (res.status === 401) {
      store.dispatch(expireSession())
      return res
    }

    const session = store.getState().session

    if (!isUnEncryptedPath(res.config.url)) {
      const sessionIsEncrypted = session.isPwaLoading === "true"
      const localIsEncrypted = localStorage.getItem("isPwaLoading") === "true"
      if (sessionIsEncrypted || localIsEncrypted) {
        let decryptedWrapperResponse = null
        try {
          const encryptedWrapperResponse = String(res.data)
          decryptedWrapperResponse = await aesDecryptData(
            encryptedWrapperResponse,
            uuid,
          )
          res.data = decryptedWrapperResponse
        } catch (error) {
          consoleError(
            `An exception occurred while decrypting wrapper Response:\n`,
            res.data,
            "Error:\n",
            error,
          )
          throw new Error("Failed to decrypt wrapper response")
        }
      }
    }

    let checksum = res.headers["x-payload-checksum"]
    if (checksum && res.status === 200) {
      if (!isResponseValid(res, checksum)) {
        consoleError("Invalid response")
        throw new Error("Invalid response")
      }
    }

    const path = res.config.url
    const encryptedResponse = res.data?.encryptedResponse

    if (
      isValidSuccessResponse(path, urlsExemptFromValidation) &&
      !res.data.success
    ) {
      consoleExceptions(
        `An exception occurred for sessionToken ${session.sessionToken} and path ${path}.\nResponse:\n`,
        res.data,
        path,
      )
    }

    if (isEncryptedPath(path) && encryptedResponse) {
      const key = CryptoJs.enc.Base64.parse(session.encryptionKey)
      const iv = CryptoJs.enc.Base64.parse(session.encryptionIv)
      const encoder = CryptoJs.enc.Utf8

      let decryptedResponse = null
      try {
        decryptedResponse = CryptoJs.AES.decrypt(encryptedResponse, key, {
          iv,
        }).toString(encoder)
      } catch (error) {
        consoleError(
          `An exception occurred while decrypting response for sessionToken ${session.sessionToken} and path ${path}.\nResponse:\n`,
          res.data,
          "Error:\n",
          error,
        )
        throw new Error("Failed to decrypt response")
      }

      if (!decryptedResponse) {
        consoleError(
          `Failed to decrypt response for sessionToken ${session.sessionToken} and path ${path}.\nResponse:\n`,
          res.data,
        )
        throw new Error("Failed to decrypt response")
      }

      try {
        res.data = JSON.parse(decryptedResponse)
      } catch (error) {
        // don't log the decrypted response or error here to avoid logging PII data
        consoleError(
          `An exception occurred while parsing decrypted response as JSON for sessionToken ${session.sessionToken} and path ${path}.\nResponse:\n`,
          res.data,
        )
        throw new Error("Failed to decrypt response")
      }
    }

    return res
  },
  function (error) {
    consoleExceptions(error)
    return Promise.reject(error)
  },
)

export default Service
