import {
  SET_ACS_UNIQUE_ID,
  SET_CLIENT_PARAMS,
  SET_CSS_LOADED,
  SET_CURRENT_OFFER,
  SET_DISABLED_FUNCTIONS_BLOCK_CODE,
  SET_EVENT_FLOW,
  SET_FIRST_TIME_LOADED,
  SET_ISSUER,
  SET_MULTICARD_DESIGN_ID,
  SET_NUDGES,
  SET_ONBOARDING_URL,
  SET_REDIRECT_URL,
  SET_REPAYMENT_DETAILS,
  SET_SELECTED_EMI,
  SET_SESSION,
  SET_SESSION_EXPIRY,
  SET_SESSION_INACTIVE_EXPIRY,
} from "../ActionTypes"

export const setSession =
  (data, isStandalone = false) =>
  dispatch => {
    if (!isStandalone) {
      localStorage.setItem("sessionToken", data.sessionToken)
      localStorage.setItem("deviceToken", data.deviceToken)
      localStorage.setItem("encryptionKey", data.encryptionKey)
      localStorage.setItem("encryptionIv", data.encryptionIv)
      localStorage.setItem("isPwaLoading", data.isPwaLoading)
    }

    dispatch({
      type: SET_SESSION,
      payload: data,
    })
  }

export const setUniqueId = data => dispatch => {
  dispatch({
    type: SET_ACS_UNIQUE_ID,
    payload: data,
  })
}

export const setSessionInactiveExpiry = data => dispatch => {
  localStorage.setItem(
    "pwaSessionInActiveTimeoutMinutes",
    data?.pwaSessionInActiveTimeoutMinutes || 2,
  )

  dispatch({
    type: SET_SESSION_INACTIVE_EXPIRY,
    payload: data,
  })
}

export const setRedirectUrl = data => dispatch => {
  localStorage.setItem("redirectUrl", data.redirectUrl)

  dispatch({
    type: SET_REDIRECT_URL,
    payload: data,
  })
}

export const setOnboardingUrl = data => dispatch => {
  localStorage.setItem("onboardingUrl", data.onboardingUrl)

  dispatch({
    type: SET_ONBOARDING_URL,
    payload: data,
  })
}

export const setRepaymentDetails = data => dispatch => {
  localStorage.setItem("setRepaymentUrl", data.setRepaymentUrl)

  dispatch({
    type: SET_REPAYMENT_DETAILS,
    payload: data,
  })
}

export const setSelectedEmi = data => dispatch => {
  dispatch({
    type: SET_SELECTED_EMI,
    payload: data,
  })
}

export const setCurrentOffer = data => dispatch => {
  dispatch({
    type: SET_CURRENT_OFFER,
    payload: data,
  })
}

export const expireSession = () => dispatch => {
  dispatch({
    type: SET_SESSION_EXPIRY,
    payload: { sessionExpired: true },
  })
}

export const setdisabledFunctionsByBlockCode = data => dispatch => {
  dispatch({
    type: SET_DISABLED_FUNCTIONS_BLOCK_CODE,
    payload: data,
  })
}

export const setFirstTimeLoaded = data => dispatch => {
  dispatch({
    type: SET_FIRST_TIME_LOADED,
    payload: data,
  })
}

export const setCssLoaded = data => dispatch => {
  dispatch({
    type: SET_CSS_LOADED,
    payload: data,
  })
}

export const setIssuer = data => dispatch => {
  dispatch({
    type: SET_ISSUER,
    payload: data,
  })
}

export const setEventFlow = data => dispatch => {
  dispatch({
    type: SET_EVENT_FLOW,
    payload: data,
  })
}

export const setClientParams = data => dispatch => {
  dispatch({
    type: SET_CLIENT_PARAMS,
    payload: data,
  })
}

export const setAllNudges = data => dispatch => {
  dispatch({
    type: SET_NUDGES,
    payload: data,
  })
}

export const setDesignId = data => dispatch => {
  dispatch({
    type: SET_MULTICARD_DESIGN_ID,
    payload: data,
  })
}
