import { useDispatch, useSelector } from "react-redux"
import { Header } from "../../../core/headers"
import { useNavigate } from "react-router-dom"
import ArrowDown from "../../../Rewards/v3/DownArrow"
import ArrowRight from "../../../svg/v3/arrowRight"
import { ErrorType, NudgeContentType } from "../../../../utils/constants"
import { useContext, useEffect, useState } from "react"
import CubeAppLoader from "../../InAppLoader"
import { nudgeVariants } from "../../../../utils/enums"
import { useTranslation } from "react-i18next"
import { setAllNudges } from "../../../../store/actions/Session"
import {
  consoleError,
  resetErrorTryAgainCount,
} from "../../../../utils/functions"
import AccountService from "../../../../services/AccountService"
import { ErrorContext } from "../../../auth/ErrorScreenContext"

const AllNudges = () => {
  const session = useSelector(state => state.session)
  const theme = useSelector(state => state.theme)
  const user = useSelector(state => state.user)
  const allNudges = session.nudges
  const navigate = useNavigate()
  const [allPillNudges, setAllPillNudges] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { setErrorState } = useContext(ErrorContext)

  useEffect(() => {
    if (!allNudges || allNudges?.length > 0) {
      getAllNudges()
    }
    allNudges?.sort(
      (a, b) =>
        a?.displayOptions?.displayOrder - b?.displayOptions?.displayOrder,
    )

    let filteredPillNudges = allNudges?.filter(nudge => {
      const nudgeDetails = JSON.parse(
        nudge?.displayOptions?.nudgeDetails || "{}",
      )
      return nudgeDetails?.variant === nudgeVariants.PILL
    })

    setAllPillNudges(filteredPillNudges)

    setIsLoading(false)
  }, [allNudges])

  const getAllNudges = async () => {
    try {
      const response = await AccountService.getAllNudges(user.account.id)
      const result = response?.data
      if (result?.success) {
        dispatch(setAllNudges({ nudges: result.data }))
        resetErrorTryAgainCount()
      } else {
        setErrorState(response?.status, () => getAllNudges())
      }
    } catch (error) {
      if (!navigator.onLine) {
        setErrorState(ErrorType.NO_INTERNET_ERROR, () => getAllNudges())
      } else {
        consoleError(error)
        setErrorState(ErrorType.INTERNAL_ERROR, () => getAllNudges())
      }
    }
  }

  const handleRedirectionUrl = item => {
    try {
      const nudgeDetails = JSON.parse(item?.displayOptions?.nudgeDetails)
      const redirectionUrl = nudgeDetails?.redirectionUrl

      if (redirectionUrl.startsWith("https")) {
        window.location.href = redirectionUrl
      } else {
        navigate(redirectionUrl)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const getColorsV3 = (color, comp) => {
    if (color && color !== "") {
      return color
    } else {
      switch (comp) {
        case NudgeContentType.SUBTITLE:
          return theme.v3.rawColors.primaryBase.color2
        case NudgeContentType.BACKGROUND:
          return theme.v3.rawColors.primary.color5
        default:
          return theme.v3.rawColors.primary.color1
      }
    }
  }

  return (
    <>
      {isLoading ? (
        <CubeAppLoader />
      ) : (
        <div>
          <Header onBack={() => navigate("/")} />
          <div className='v3-all-nudges-head'>
            <div onClick={() => window.history.go(-1)}>
              <ArrowDown />
            </div>
            <div className='v3-all-nudges-head-text'>
              {t("messages.allNotifications")}
            </div>
          </div>
          <div className='v3-all-nudges-container'>
            {allPillNudges &&
              allPillNudges?.map((item, index) => {
                return (
                  <div className='v3-all-nudges-card' key={index}>
                    <div
                      id={item.id}
                      className='v3-nudge-smartnudges-nudge-icon'
                    >
                      <img
                        id={item.id}
                        src={
                          JSON.parse(item?.displayOptions?.nudgeDetails)
                            ?.illustrationUrl
                        }
                      />
                    </div>
                    <div
                      id={item.id}
                      className='nudge-smartnudges-nudge-nudgeTitleDescriptionContainer'
                    >
                      <div
                        id={item.id}
                        style={{
                          color: getColorsV3(
                            JSON.parse(item?.displayOptions?.nudgeDetails)
                              ?.titleColor,
                            NudgeContentType.TITLE,
                          ),
                        }}
                        className='nudge-smartnudges-nudge-nudgeTitle'
                      >
                        {JSON.parse(item?.displayOptions?.nudgeDetails)?.title}
                      </div>
                      <div
                        id={item.id}
                        style={{
                          color: getColorsV3(
                            JSON.parse(item?.displayOptions?.nudgeDetails)
                              ?.subtitleColor,
                            NudgeContentType.SUBTITLE,
                          ),
                        }}
                        className='nudge-smartnudges-nudge-nudgeDescription'
                      >
                        {
                          JSON.parse(item?.displayOptions?.nudgeDetails)
                            ?.description
                        }
                      </div>
                    </div>
                    <div
                      className='v3-nudge-smartnudges-nudge-arrow'
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "auto",
                      }}
                      onClick={() => handleRedirectionUrl(item)}
                    >
                      <ArrowRight
                        color={getColorsV3(
                          JSON.parse(item?.displayOptions?.nudgeDetails)
                            ?.titleColor,
                          NudgeContentType.ARROW,
                        )}
                      />
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      )}
    </>
  )
}

export default AllNudges
