import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import moment from "moment"
import LineBreaker from "../../../core/lineBreaker"
import {
  colorPicker,
  captureEvents,
  formatAmount,
  consoleError,
} from "../../../../utils/functions"
import { useDispatch, useSelector } from "react-redux"
import usePayButtonText from "../../../../hooks/usePayButtonText"
import {
  AU_BlockCodes,
  EventName,
  Federal_BlockCodes,
  RepaymentTypes,
  cardSections,
} from "../../../../utils/enums"
import { ErrorType, ProgramTypes } from "../../../../utils/constants"
import SparklesLogo from "../../../svg/sparkleSvg"
import ViewArrow from "../../../svg/viewArrow"
import { useNavigate } from "react-router-dom"
import { setCommonState } from "../../../../store/actions/Common"
import useWindowDimensions from "../../../../hooks/useWindowDimensionsWeb"
import Sidebar from "../../../AllTransactions/v3/webView/Sidebar"
import usePaylaterDesktopViewEnabled from "../../../../hooks/usePaylaterDesktopViewEnabled"
import AccountService from "../../../../services/AccountService"
import { ErrorContext } from "../../../auth/ErrorScreenContext"
import CustomLoader from "../../../core/loader"

const PayButton = ({
  getPayButtonText,
  payButtonStyles,
  repayUrlCall,
  isLoading,
}) => {
  const session = useSelector(state => state.session)
  return (
    <div
      className='v3-activity-section-balance-amount-cta'
      style={payButtonStyles}
      onClick={() => {
        session.disabledFunctionsByBlockCode?.includes(
          Federal_BlockCodes.PAY_BILL_DISABLE,
        ) ||
        session?.disabledFunctionsByBlockCode?.includes(
          AU_BlockCodes.PAY_BILL_DISABLE,
        ) ? (
          <></>
        ) : (
          repayUrlCall()
        )
        captureEvents({
          eventName: EventName.PAY_BILL,
          metadata: { source: EventName.HOME },
        })
      }}
    >
      {isLoading ? <CustomLoader /> : getPayButtonText}
    </div>
  )
}

const StatementInfo = ({
  daysSinceStatement,
  daysLeft,
  currentCycleStartDate,
  currentCycleEndDate,
  lastStatementStartDate,
  lastStatementEndDate,
  navigate,
}) => {
  const { t } = useTranslation()
  const MAX_DAYS = 7
  const [showCTAText, setShowCTAText] = useState(false)
  const user = useSelector(state => state.user)

  useEffect(() => {
    const todayDate = new Date()
    todayDate.setHours(0, 0, 0, 0)

    const notificationEndDate = new Date(currentCycleStartDate)
    notificationEndDate.setDate(notificationEndDate.getDate() + MAX_DAYS)

    setShowCTAText(
      todayDate >= new Date(currentCycleStartDate) &&
        todayDate <= notificationEndDate,
    )
  }, [])

  const formatDate = date => {
    return new Date(date).toLocaleDateString("en-US", {
      day: "2-digit",
      month: "short",
    })
  }

  const isWebViewEnabled = usePaylaterDesktopViewEnabled()

  return (
    <>
      {showCTAText && (
        <div
          className='v3-account-summary-highlight v3-account-summary-highlight-fully-paid'
          onClick={() => navigate("/Statement")}
          style={{
            width: isWebViewEnabled && "100%",
            marginTop: isWebViewEnabled && "-4%",
          }}
        >
          <div>
            <SparklesLogo color='var(--primary-color-1)' />
            {"  "}
            {t("AccountSummary.statementFor")}
            <span className='v3-account-summary-highlight-days v3-account-summary-highlight-days-fully-paid-days'>
              {" "}
              {formatDate(lastStatementStartDate)} -{" "}
              {formatDate(lastStatementEndDate)}
            </span>
          </div>
          <div>
            {t("ActivitySectionv3.view")} {"  "}
            <ViewArrow />
          </div>
        </div>
      )}
      {daysLeft !== null && (
        <div className='v3-account-summary-highlight'>
          <SparklesLogo />
          {"  "} {t("AccountSummary.newStatementUpcoming")}{" "}
          <span className='v3-account-summary-highlight-days'>
            {daysLeft} {daysLeft === 1 ? "Day" : "Days"}
          </span>
        </div>
      )}
    </>
  )
}

const AccountSummary = ({ props, setIsPaymentGatewayOpen, user }) => {
  const { t } = useTranslation()
  const theme = useSelector(state => state.theme)
  const session = useSelector(state => state.session)
  const featureFlags = useSelector(state => state.featureFlags)
  const screen = useSelector(state => state.screen)

  const [isLoading, setIsLoading] = useState(false)
  const [payLaterOutstanding, setPayLaterOutstanding] = useState(
    user?.summary?.account?.metadata?.PrincipalOutstanding,
  )

  const { setErrorState } = useContext(ErrorContext)

  const textColor = theme.appTextColor
  const ctaPrimaryColor = theme.color1
  const ctaSecondaryColor = theme.color2
  const widgetBackgroundColor = theme.widgetBackgroundColor

  const textBase1 = colorPicker(textColor).primary1
  const textBase2 = colorPicker(textColor).primary2

  const ctaPrimaryColor1 = colorPicker(ctaPrimaryColor).primary1
  const ctaPrimaryColor2 = colorPicker(ctaPrimaryColor).primary2

  const getPayButtonText = usePayButtonText()

  const isRepaymentFlowEnabled = session.repaymentFlow
  const currentCycleStartDate = user?.summary?.account?.currentCycleStartDate
  const currentCycleEndDate = user?.summary?.account?.currentCycleEndDate
  const lastStatementStartDate = user?.summary?.lastStatement?.fromDate
  const lastStatementEndDate = user?.summary?.lastStatement?.toDate
  const [daysLeft, setDaysLeft] = useState(null)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const nextStatementDate = new Date(currentCycleEndDate)
  nextStatementDate.setDate(nextStatementDate.getDate() + 1)
  const [sidebarEnabled, setSidebarEnabled] = useState(false)

  const today = new Date()
  const daysSinceStatement = Math.floor(
    (today - nextStatementDate) / (1000 * 60 * 60 * 24),
  )

  const totalAmountDue = Number(user.summary.lastStatement?.balanceAmountDue)
  const totalBilledAmount = Number(user.summary.lastStatement?.totalAmountDue)
  const minimumAmountDue = Number(user.summary?.lastStatement?.minimumAmountDue)
  const totalUnbilled =
    Number(props?.creditDetails?.totalCredit) -
    Number(props?.creditDetails?.availableCredit) -
    totalAmountDue
  const windowDimensions = useWindowDimensions()

  const programType = user.programType
  const isWebViewEnabled =
    programType === ProgramTypes.PAY_LATER && windowDimensions.width >= 500

  useEffect(() => {
    if (currentCycleEndDate) {
      const endDate = new Date(currentCycleEndDate)

      const nextStatementDate = new Date(endDate)
      nextStatementDate.setDate(endDate.getDate() + 1)

      const today = new Date()

      const diff = Math.ceil(
        (nextStatementDate - today) / (1000 * 60 * 60 * 24),
      )

      if (diff > 0 && diff <= 3) {
        setDaysLeft(diff)
      } else {
        setDaysLeft(null)
      }
    }
  }, [currentCycleEndDate])

  const handleShowSummaryBottomSheet = () => {
    if (isWebViewEnabled) {
      setSidebarEnabled(true)
    }
    dispatch(setCommonState({ showSummaryBottomSheet: true }))
  }
  const repayUrlCall = async () => {
    if (programType === ProgramTypes.PAY_LATER) {
      let amountToBePaid =
        Number(props?.creditDetails.totalCredit) -
        Number(props?.creditDetails.availableCredit)
      setIsLoading(true)
      try {
        const response =
          await AccountService.generatePaylaterRepaymentRedirectionUrl({
            accountId: user.account?.id,
            category: "REPAYMENT",
            sso: "REQUIRED",
            amount: amountToBePaid,
            metadata: {
              redirectionUrl: process.env.REACT_APP_URL,
            },
          })
        if (response.status === 200) {
          window.location.href = response.data.data.redirectUrl
        } else {
          setErrorState(response?.status, () => repayUrlCall())
          consoleError(response?.errors)
        }
      } catch (err) {
        if (!navigator.onLine) {
          setErrorState(ErrorType.NO_INTERNET_ERROR, () => repayUrlCall())
        } else {
          consoleError(err)
          setErrorState(ErrorType.INTERNAL_ERROR, () => repayUrlCall())
        }
      } finally {
        setIsLoading(false)
      }
    } else {
      if (screen.home.repayments.repaymentType !== RepaymentTypes.S2S_PG) {
        window.location.href = session.repaymentUrl
      } else setIsPaymentGatewayOpen(true)
    }
  }

  const renderMinimumAmountDue = minimumAmountDue => {
    if (
      !minimumAmountDue ||
      totalBilledAmount - minimumAmountDue >= totalAmountDue
    ) {
      return null
    }

    return (
      <div className='v3-activity-section-minimum-amount-due'>
        {t("AccountSummary.minimumAmountDue")}
        {formatAmount(minimumAmountDue)}
      </div>
    )
  }

  const renderPayBillWidget = () => {
    const payLaterMetaData = user?.summary?.account?.metadata
    const penaltyDues = payLaterMetaData?.penaltyDues
    const installmentDues = payLaterMetaData?.instalmentDues

    if (penaltyDues + installmentDues > 0) {
      setPayLaterOutstanding(penaltyDues + installmentDues)
    }

    if (props?.showReminder && Object.keys(props?.showReminder)?.length === 0) {
      const payButtonStyles = {
        backgroundColor:
          Number(props?.creditDetails.totalCredit) -
            Number(props?.creditDetails.availableCredit) >
            0 && theme.v3.cssVars.primaryBase.color1,
      }

      const outstandingAmount = isWebViewEnabled
        ? formatAmount(Number(payLaterOutstanding))
        : formatAmount(
            Number(props?.creditDetails.totalCredit) -
              Number(props?.creditDetails.availableCredit),
            true,
          )
      return (
        <>
          <div className='v3-activity-section-balance-widget-wrapper'>
            <div
              className='v3-activity-section-summary-credit-balance-section'
              onClick={handleShowSummaryBottomSheet}
            >
              {t("AccountSummary.availableCreditLimit")}
              <span className='v3-activity-section-summary-credit-balance-span'>
                {formatAmount(props?.creditDetails?.availableCredit)}
              </span>
              <span className='v3-activity-section-summary-credit-arrow-span'>
                <ViewArrow color='var(--System-Palette-neutral-2, #A8A8A8)' />
              </span>
            </div>
            <div className='v3-activity-section-balance'>
              <div className='v3-activity-section-balance-amount-section'>
                <div
                  className='v3-activity-section-balance-amount-section-value'
                  style={{ color: textBase1 }}
                >
                  {outstandingAmount}
                </div>
                <div
                  className='v3-activity-section-balance-amount-section-due'
                  style={{ textAlign: "left" }}
                >
                  {t("AccountSummary.unbilledOutsatndingAmt")}
                </div>
                {renderMinimumAmountDue(minimumAmountDue)}
              </div>
              {isRepaymentFlowEnabled && getPayButtonText && (
                <PayButton
                  getPayButtonText={getPayButtonText}
                  payButtonStyles={payButtonStyles}
                  isLoading={isLoading}
                  repayUrlCall={repayUrlCall}
                />
              )}
            </div>
          </div>
          <StatementInfo
            daysSinceStatement={daysSinceStatement}
            daysLeft={daysLeft}
            currentCycleStartDate={currentCycleStartDate}
            currentCycleEndDate={currentCycleEndDate}
            lastStatementStartDate={lastStatementStartDate}
            lastStatementEndDate={lastStatementEndDate}
            navigate={navigate}
          />
        </>
      )
    } else {
      if (props?.showReminder.amount <= 0) {
        // fully paid state
        const payButtonStyles = {
          backgroundColor:
            Number(props.creditDetails.totalCredit) -
              Number(props.creditDetails.availableCredit) ===
            0
              ? theme.v3.cssVars.tertiaryNeutral.color2
              : theme.v3.cssVars.secondary.color1,
          color: theme.v3.cssVars.leadingWhite,
        }
        return (
          <>
            <div
              className='v3-activity-section-balance-widget-wrapper'
              style={{ width: isWebViewEnabled && "100%" }}
            >
              <div
                className='v3-activity-section-summary-credit-balance-section'
                onClick={handleShowSummaryBottomSheet}
              >
                {t("AccountSummary.availableCreditLimit")}
                <span className='v3-activity-section-summary-credit-balance-span'>
                  {formatAmount(props?.creditDetails.availableCredit)}
                </span>
                <span className='v3-activity-section-summary-credit-arrow-span'>
                  <ViewArrow color='var(--System-Palette-neutral-2, #A8A8A8)' />
                </span>
              </div>
              <div className='v3-activity-section-balance'>
                <div className='v3-activity-section-balance-amount-section'>
                  {Number(props.creditDetails.totalCredit) -
                    Number(props.creditDetails.availableCredit) <=
                  0 ? (
                    <>
                      <div
                        className='v3-activity-section-balance-amount-section-value v3-activity-balance-amount-section-fully-paid-value'
                        style={{
                          color: theme.v3.cssVars.tertiaryPositive.color1,
                        }}
                      >
                        {t("AccountSummary.fullyPaid")}
                      </div>
                      <div className='v3-activity-section-balance-amount-section-due'>
                        {t("AccountSummary.noBillsDue")}
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className='v3-activity-section-balance-amount-section-value'
                        style={{ color: textBase1 }}
                      >
                        {isWebViewEnabled
                          ? formatAmount(Number(payLaterOutstanding))
                          : formatAmount(totalUnbilled)}
                      </div>
                      <div
                        className='v3-activity-section-balance-amount-section-due'
                        style={{ textAlign: "left" }}
                      >
                        {t("AccountSummary.unbilledOutsatndingAmt")}
                      </div>
                    </>
                  )}
                  {renderMinimumAmountDue(minimumAmountDue)}
                </div>
                {isRepaymentFlowEnabled && getPayButtonText && (
                  <PayButton
                    getPayButtonText={getPayButtonText}
                    payButtonStyles={payButtonStyles}
                    isLoading={isLoading}
                    repayUrlCall={repayUrlCall}
                  />
                )}
              </div>
            </div>
            <StatementInfo
              daysSinceStatement={daysSinceStatement}
              daysLeft={daysLeft}
              currentCycleStartDate={currentCycleStartDate}
              currentCycleEndDate={currentCycleEndDate}
              lastStatementStartDate={lastStatementStartDate}
              lastStatementEndDate={lastStatementEndDate}
              navigate={navigate}
            />
          </>
        )
      } else {
        if (moment() > moment(props?.showReminder.dueDate)) {
          //overdue state
          const payButtonStyles = {
            background: theme.v3.cssVars.tertiaryNegative.color1,
          }
          return (
            <>
              <div
                className='v3-activity-section-balance-widget-wrapper'
                style={{ width: isWebViewEnabled && "100%" }}
              >
                <div
                  className='v3-activity-section-summary-credit-balance-section'
                  onClick={handleShowSummaryBottomSheet}
                >
                  {t("AccountSummary.availableCreditLimit")}
                  <span className='v3-activity-section-summary-credit-balance-span'>
                    {formatAmount(props?.creditDetails.availableCredit)}
                  </span>
                  <span className='v3-activity-section-summary-credit-arrow-span'>
                    <ViewArrow color='var(--System-Palette-neutral-2, #A8A8A8)' />
                  </span>
                </div>
                <div className='v3-activity-section-balance'>
                  <div className='v3-activity-section-balance-amount-section'>
                    <div
                      className='v3-activity-section-balance-amount-section-value'
                      style={{ color: textBase1 }}
                    >
                      {isWebViewEnabled
                        ? formatAmount(Number(payLaterOutstanding))
                        : formatAmount(props?.showReminder.amount)}
                    </div>
                    <div className='v3-activity-section-balance-amount-section-overdue'>
                      {t("AccountSummary.billOverdueOn", {
                        date: moment(props?.showReminder.dueDate).format(
                          "DD MMM 'YY",
                        ),
                      })}
                    </div>
                    {renderMinimumAmountDue(minimumAmountDue)}
                  </div>
                  {isRepaymentFlowEnabled && getPayButtonText && (
                    <PayButton
                      getPayButtonText={getPayButtonText}
                      payButtonStyles={payButtonStyles}
                      isLoading={isLoading}
                      repayUrlCall={repayUrlCall}
                    />
                  )}
                </div>
              </div>
              <StatementInfo
                daysSinceStatement={daysSinceStatement}
                daysLeft={daysLeft}
                currentCycleStartDate={currentCycleStartDate}
                currentCycleEndDate={currentCycleEndDate}
                lastStatementStartDate={lastStatementStartDate}
                lastStatementEndDate={lastStatementEndDate}
                navigate={navigate}
              />
            </>
          )
        } else {
          if (
            props?.showReminder.amount !== props?.showReminder.totalAmountDue
          ) {
            //partially paid
            const payButtonStyles = {}
            return (
              <>
                <div
                  className='v3-activity-section-balance-widget-wrapper'
                  style={{
                    background: widgetBackgroundColor,
                    width: isWebViewEnabled && "100%",
                  }}
                >
                  <div
                    className='v3-activity-section-summary-credit-balance-section'
                    onClick={handleShowSummaryBottomSheet}
                  >
                    {t("AccountSummary.availableCreditLimit")}
                    <span className='v3-activity-section-summary-credit-balance-span'>
                      {isWebViewEnabled
                        ? formatAmount(Number(payLaterOutstanding))
                        : formatAmount(props?.creditDetails.availableCredit)}
                    </span>
                    <span className='v3-activity-section-summary-credit-arrow-span'>
                      <ViewArrow color='var(--System-Palette-neutral-2, #A8A8A8)' />
                    </span>
                  </div>
                  <div className='v3-activity-section-balance'>
                    <div className='v3-activity-section-balance-amount-section'>
                      <div
                        className='v3-activity-section-balance-amount-section-value'
                        style={{ color: textBase1 }}
                      >
                        {formatAmount(props?.showReminder.amount)}
                      </div>
                      <div className='v3-activity-section-balance-amount-section-due'>
                        {t("AccountSummary.remainingDueOn", {
                          date: moment(props?.showReminder.dueDate).format(
                            "DD MMM 'YY",
                          ),
                        })}
                      </div>
                      {renderMinimumAmountDue(minimumAmountDue)}
                    </div>
                    {isRepaymentFlowEnabled && getPayButtonText && (
                      <PayButton
                        getPayButtonText={getPayButtonText}
                        payButtonStyles={payButtonStyles}
                        isLoading={isLoading}
                        repayUrlCall={repayUrlCall}
                      />
                    )}
                  </div>
                </div>
                <StatementInfo
                  daysSinceStatement={daysSinceStatement}
                  daysLeft={daysLeft}
                  currentCycleStartDate={currentCycleStartDate}
                  currentCycleEndDate={currentCycleEndDate}
                  lastStatementStartDate={lastStatementStartDate}
                  lastStatementEndDate={lastStatementEndDate}
                  navigate={navigate}
                />
              </>
            )
          } else {
            // general state (not partially paid)
            const payButtonStyles = {}
            return (
              <>
                <div
                  className='v3-activity-section-balance-widget-wrapper'
                  style={{ width: isWebViewEnabled && "100%" }}
                >
                  <div
                    className='v3-activity-section-summary-credit-balance-section'
                    onClick={handleShowSummaryBottomSheet}
                  >
                    {t("AccountSummary.availableCreditLimit")}
                    <span className='v3-activity-section-summary-credit-balance-span'>
                      {isWebViewEnabled
                        ? formatAmount(Number(payLaterOutstanding))
                        : formatAmount(props?.creditDetails.availableCredit)}
                    </span>
                    <span className='v3-activity-section-summary-credit-arrow-span'>
                      <ViewArrow color='var(--System-Palette-neutral-2, #A8A8A8)' />
                    </span>
                  </div>
                  <div className='v3-activity-section-balance'>
                    <div className='v3-activity-section-balance-amount-section'>
                      <div
                        className='v3-activity-section-balance-amount-section-value'
                        style={{ color: textBase1 }}
                      >
                        {formatAmount(props?.showReminder.amount)}
                      </div>
                      <div className='v3-activity-section-balance-amount-section-due'>
                        {t("AccountSummary.billDueOn")}{" "}
                        {moment(props?.showReminder.dueDate).format(
                          "DD MMM 'YY",
                        )}
                      </div>
                      {renderMinimumAmountDue(minimumAmountDue)}
                    </div>
                    {isRepaymentFlowEnabled && getPayButtonText && (
                      <PayButton
                        getPayButtonText={getPayButtonText}
                        payButtonStyles={payButtonStyles}
                        isLoading={isLoading}
                        repayUrlCall={repayUrlCall}
                      />
                    )}
                  </div>
                </div>
                <StatementInfo
                  daysSinceStatement={daysSinceStatement}
                  daysLeft={daysLeft}
                  currentCycleStartDate={currentCycleStartDate}
                  currentCycleEndDate={currentCycleEndDate}
                  lastStatementStartDate={lastStatementStartDate}
                  lastStatementEndDate={lastStatementEndDate}
                  navigate={navigate}
                />
              </>
            )
          }
        }
      }
    }
  }

  const handleCloseSidebar = () => {
    setSidebarEnabled(false)
  }

  return (
    <>
      <div className={!isWebViewEnabled && "v3-web-view-activity-container"}>
        {renderPayBillWidget()}
      </div>
      {sidebarEnabled && (
        <>
          <div
            className='v3-web-all-transactions-sidebar-backdrop'
            style={{
              height: "100vh",
              width: "100vw",
              backgroundColor: "#000000",
              opacity: "0.8",
              position: "fixed",
              top: "0",
              left: "0",
              zIndex: "2",
            }}
          ></div>
          <Sidebar onClose={handleCloseSidebar} currentPage={"HOME"} />
        </>
      )}
    </>
  )
}

export default AccountSummary
