import React, { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { Header } from "../../../core"
import { ColorButton, WhiteButton } from "../../../core/buttons"
import TokenisationLock from "../../../svg/v3/tokenisationLock"
import SearchInput from "../../../OffersList/v3/Components/searchInput"
import { Checkbox } from "../../../../core"
import DividerV3 from "../../../core/Divider/v3/DividerV3"
import CardService from "../../../../services/CardService"
import ApiIds from "../../../../auth/ApiIds"
import { useBottomSheet } from "../../../auth/BottomSheetContext"
import { handleApiAuth } from "../../../../utils/auth"
import { BottomSheetType } from "../../../../utils/enums"
import { useNavigate } from "react-router-dom"
import hfLogoColored from "../../../../assets/images/v3/active/hfLogoColored.svg"
import { useTranslation } from "react-i18next"
import TokenisationDefaultMerchant from "../../../svg/v3/TokenisationDefaultMerchant"
import CubeAppLoader from "../../../core/InAppLoader"

const AddCardToMerchant = () => {
  const theme = useSelector(state => state.theme)
  const user = useSelector(state => state.user)
  const config = useSelector(state => state.config)
  const screen = useSelector(state => state.screen)
  const [isInputFocused, setInputFocused] = useState(false)
  const [search, setSearch] = useState("")

  const [popularMerchants, setPopularMerchants] = useState([])
  const [allMerchants, setAllMerchants] = useState([])

  const [checkedMerchant, setCheckedMerchant] = useState([])

  const bottomSheetContext = useBottomSheet()
  const [fetchMerchantRetry, setFetchMerchantRetry] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()
  const { t } = useTranslation()

  useEffect(() => {
    ;(async function () {
      try {
        let data = {
          cardId: user.cards[0].id,
        }
        const response = await CardService.tokenizationFetchEligibleMerchants(
          null,
          data,
        )
        setIsLoading(false)
        if (response.status == 200) {
          let merchants = response.data.data.merchants
          let popularMerchantTemp = []
          let allMerchantTemp = []

          merchants.forEach((merchant, index) => {
            if (index < 3) {
              popularMerchantTemp.push(merchant)
            } else {
              allMerchantTemp.push(merchant)
            }
          })
          setAllMerchants(allMerchantTemp)
          setPopularMerchants(popularMerchantTemp)
        } else {
          handleFetchMerchantFailureState()
        }
      } catch (err) {
        handleFetchMerchantFailureState()
      }
    })()
  }, [fetchMerchantRetry])

  const handleCheckboxClicked = id => {
    let checkMerchantTemp = [...checkedMerchant]
    if (checkMerchantTemp.includes(id)) {
      let index = checkMerchantTemp.indexOf(id)
      checkMerchantTemp.splice(index, 1)
    } else {
      checkMerchantTemp.push(id)
    }
    setCheckedMerchant(checkMerchantTemp)
  }

  const handleFailureState = () => {
    bottomSheetContext.openBottomSheet(
      BottomSheetType.FAILURE,
      t("merchant.unableToAddErrorText"),
      t("merchant.unsuccessfulAttempts"),
      null,
      null,
      () => handleConfirmSelection(),
    )
  }

  const handleConfirmSelection = () => {
    ;(async function () {
      try {
        await handleApiAuth({
          apiId: ApiIds.TOKENIZATION_PROVISION,
          onAuthSuccess: onProvisionAuthSuccess,
          onAuthFailure: handleFailureState,
          onAuthCancel: handleFailureState,
          otpReason: t("merchant.forTokenisationProvision"),
          mpinReason: t("merchant.forTokenisationProvision"),
          version: config?.version,
          bottomSheetContext: bottomSheetContext,
        })
      } catch (err) {}
    })()
  }

  const onProvisionAuthSuccess = async apiToken => {
    try {
      let data = {
        cardId: user.cards[0].id,
        merchantIds: checkedMerchant,
      }
      const response = await CardService.tokenizationProvision(apiToken, data)

      if (response.status == 200) {
        bottomSheetContext.openBottomSheet(
          BottomSheetType.SUCCESS,
          t("merchant.requestSendSuccess"),
          ".",
          "/ManageCard/Cards/Saved",
        )
        // handleFailureState()
      } else {
        handleFailureState()
      }
    } catch (err) {
      console.log(err)
      handleFailureState()
    }
  }

  const handleFetchMerchantFailureState = () => {
    bottomSheetContext.openBottomSheet(
      BottomSheetType.FAILURE,
      t("merchant.CouldNotFetchMerchants"),
      "",
      null,
      null,
      false,
      false,
    )
    setTimeout(() => {
      navigate("/")
    }, 2000)
  }

  const getMerchant = merchant => {
    return (
      <div className='tokenisation-add-card-merchant-container'>
        {merchant?.base64Image ? (
          <img
            src={`data:image/jpg;base64,${merchant?.base64Image}`}
            width={"10px"}
            className='tokenisation-add-card-inside-icon'
          />
        ) : (
          <TokenisationDefaultMerchant
            color={theme.v3.cssVars.primary.color1}
          />
        )}
        <span className='tokenisation-add-card-inside-text'>
          {merchant.name}
        </span>
        <div
          onClick={() => handleCheckboxClicked(merchant.merchantId)}
          className='tokenisation-add-card-inside-check-container'
        >
          <Checkbox
            isChecked={checkedMerchant.includes(merchant.merchantId)}
            backgroundColor={
              checkedMerchant.includes(merchant.merchantId)
                ? theme.v3.rawColors.secondary.color1
                : null
            }
          />
        </div>
      </div>
    )
  }

  return (
    <div className='v3-add-card-to-merchant-container'>
      <Header
        text={t("merchant.addCardToMerchant")}
        onBack={async () => {
          window.history.go(-1)
        }}
      />

      {/* <div className='tokenisation-add-card-search-merchant-container'>
        <SearchInput
          placeholder={"Search merchants by name"}
          value={search}
          onInputChange={value => setSearch(value)}
          focused={isInputFocused}
          setFocused={setInputFocused}
          opacity='0.8'
        />
      </div> */}

      {isLoading ? (
        <CubeAppLoader />
      ) : (
        <>
          <div className='tokenisation-add-card-popular-merchant-container'>
            {t("merchant.PopularMerchants")}
          </div>

          {popularMerchants &&
            popularMerchants.map(merchant => {
              return (
                <>
                  {getMerchant(merchant)}
                  <div className='tokenisation-add-card-divider-container'>
                    <DividerV3 />
                  </div>
                </>
              )
            })}

          {allMerchants && allMerchants?.length > 0 && (
            <div className='tokenisation-add-card-all-merchants-container'>
              {t("merchant.allMerchants")}
            </div>
          )}

          {allMerchants &&
            allMerchants.map(merchant => {
              return (
                <>
                  {getMerchant(merchant)}
                  <div className='tokenisation-add-card-divider-container'>
                    <DividerV3 />
                  </div>
                </>
              )
            })}
        </>
      )}
      <div className='v3-tokenisation-hf-branding-container'>
        <div className='v3-tokenization-hf-branding'>
          <div className='v3-home-hf-branding-text v3-tokenization-hf-branding-text  '>
            {t("loader.poweredBy")}
          </div>
          <div className='v3-home-hf-branding-logo'>
            <img src={hfLogoColored} />
          </div>
        </div>
      </div>

      <div className='tokenisation-add-card-cta-section'>
        <div className='tokenisation-add-card-cta-container'>
          <div>
            <TokenisationLock />
          </div>

          <div className='tokenisation-add-card-cta-text'>
            {t("merchant.saveYourCardMerchant")}
          </div>
        </div>
        <ColorButton
          text={t("merchant.confirmSelection")}
          margin='auto'
          onPress={() => handleConfirmSelection()}
          primaryColor={theme.v3.rawColors.secondary.color1}
          color={theme.v3.rawColors.leadingWhite}
          isDisable={checkedMerchant.length == 0}
        />
      </div>
    </div>
  )
}

export default AddCardToMerchant
