import React, { useEffect, useState } from "react"
import { Header } from "../core"
import { useLocation, useNavigate } from "react-router-dom"
import Calender from "../svg/v3/Calender"
import ArrowDown from "../svg/arrowDown"
import Cashback from "../svg/v3/Cashback"
import { useSelector } from "react-redux"
import FilterByDate from "../AllTransactions/v3/filterByDateV3"
import { BottomSheet } from "react-spring-bottom-sheet"
import moment from "moment"
import { customDateRangeFilterFields } from "../../utils/enums"
import Cross from "../svg/v3/Cross"
import AccountService from "../../services/AccountService"
import Transaction_v3 from "../core/Transaction_v3"
import {
  formatAmount,
  formatToWholeCurrency,
  getFormatedBillingCycle,
} from "../../utils/functions"
import MerchantFilterBottmSheet from "./merchantFilterBottomSheet"
import { useTranslation } from "react-i18next"
import {
  DurationDateType,
  SpendTransactionDateIntervalTypes,
  TransactionDateTypes,
} from "../../utils/constants"

const CategoryInsight = () => {
  const { t } = useTranslation()
  const theme = useSelector(state => state.theme)
  const user = useSelector(state => state.user)
  const screen = useSelector(state => state.screen)
  const navigate = useNavigate()
  const { state } = useLocation()

  const configuredGroups = screen.analyzeSpends.spendCategory
  const { category } = state
  const categoryImage = configuredGroups?.find(
    item => item.groupingField === category?.groupId,
  )?.iconUrl
  const [isDateBottomSheetOpen, setIsDateBottomSheetOpen] = useState(false)
  const [billingCycles, setBillingCycles] = useState([])
  const [selectedCycle, setSelectedCycle] = useState()
  const [customDateRange, setCustomDateRange] = useState()
  const [invalidCustomDateRange, setInvalidCustomDateRange] = useState()
  const [dateType, setDateType] = useState()
  const [isCustomDateRangeSelected, setIsCustomDateRangeSelected] = useState()
  const [filteredCycle, setFilteredCycle] = useState()
  const [filterMerchants, setFilterMerchants] = useState({
    enabled: false,
    selectedMerchants: [],
  })
  const [merchantSelect, setMerchantSelect] = useState()
  const [dateObject, setDateObject] = useState({
    type: SpendTransactionDateIntervalTypes.RANGE,
    dateType: TransactionDateTypes.TRANSACTION_DATE,
    startDate: null,
    endDate: null,
  })
  const [merchants, setMerchants] = useState(category?.merchantList)
  const [transactionsData, setTransactionsData] = useState()
  const lastStatement = user.summary.lastStatement

  const getBillingCycles = async () => {
    const lastStatementToDate = user.summary.lastStatement.toDate
    if (!lastStatementToDate) return
    try {
      let toDate = new Date(lastStatementToDate)
      let fromDate = new Date(toDate.getTime() - 210 * 24 * 60 * 60 * 1000)
      let finalToDate = moment(lastStatementToDate).format("YYYY-MM-DD")
      let finalFromDate = moment(fromDate).format("YYYY-MM-DD")

      const statementsResponse = await AccountService.getBillingCycles({
        accountId: user?.account?.id,
        from: finalFromDate,
        to: finalToDate,
      })
      if (statementsResponse.data.success) {
        setBillingCycles(prev => [
          ...prev,
          ...statementsResponse.data.data.statements,
        ])
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const formattedBillingCycle = getFormatedBillingCycle(
      lastStatement.fromDate,
      lastStatement.toDate,
    )
    setSelectedCycle({
      label: formattedBillingCycle,
      id: lastStatement.id,
      index: 0,
    })
    setFilteredCycle({ label: formattedBillingCycle })
    getBillingCycles()
  }, [])
  useEffect(() => {
    if (billingCycles.length === 0) return

    if (!dateObject?.startDate || !dateObject?.endDate) {
      setDateObject({
        type: SpendTransactionDateIntervalTypes.RANGE,
        dateType: TransactionDateTypes.TRANSACTION_DATE,
        startDate: moment(billingCycles[0].fromDate).format("YYYY-MM-DD"),
        endDate: moment(billingCycles[0].toDate).format("YYYY-MM-DD"),
      })
    } else {
      if (!filteredCycle?.from || !filteredCycle?.to) return
      setDateObject({
        type: SpendTransactionDateIntervalTypes.RANGE,
        dateType: TransactionDateTypes.TRANSACTION_DATE,
        startDate: filteredCycle?.from,
        endDate: filteredCycle?.to,
      })
    }
  }, [billingCycles, filteredCycle])

  const getSpendGroupTransactions = async () => {
    try {
      const response = await AccountService.getSpendCategory(
        user?.account?.id,
        category?.groupId,
        {
          startDate: dateObject?.startDate,
          endDate: dateObject?.endDate,
          merchantIds: filterMerchants.selectedMerchants.map(
            merchant => merchant.merchantId,
          ),
        },
      )
      if (response.data.success) {
        setTransactionsData({
          transactions: response.data.data.transactions,
          transactionCount: response.data.data.spendGroup.countOfTxns,
          totalSpent: response.data.data.spendGroup.totalSpend,
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (!dateObject?.startDate || !dateObject?.endDate) return
    getSpendGroupTransactions()
  }, [dateObject, filterMerchants])

  const handleDateSelect = (label, from, to) => {
    setInvalidCustomDateRange({
      invalidRange: false,
      durationExceeded: false,
      invalidField: null,
    })
    setCustomDateRange({
      from: null,
      to: null,
    })
    if (label === DurationDateType.CUSTOM_RANGE)
      setIsCustomDateRangeSelected(true)
    else setIsCustomDateRangeSelected(false)
    setSelectedCycle({
      label: label,
      from: moment(from).format("YYYY-MM-DD"),
      to: moment(to).format("YYYY-MM-DD"),
    })
  }
  const handleApplyFilter = () => {
    if (
      isCustomDateRangeSelected &&
      customDateRange.from?.length > 0 &&
      customDateRange.to?.length > 0
    ) {
      const differenceInDays = moment(customDateRange.to, "DD/MM/YYYY").diff(
        moment(customDateRange.from, "DD/MM/YYYY"),
        "days",
      )

      if (differenceInDays > 90) {
        setInvalidCustomDateRange({
          invalidRange: false,
          durationExceeded: true,
          invalidField: null,
        })
        return
      }

      if (
        !moment(customDateRange.from, "DD/MM/YYYY", true).isValid() ||
        customDateRange.from?.length !== 10 ||
        moment(customDateRange.from, "DD/MM/YYYY").isAfter(
          new Date(),
          "DD/MM/YYYY",
        )
      ) {
        setInvalidCustomDateRange({
          invalidRange: true,
          durationExceeded: false,
          invalidField: customDateRangeFilterFields.FROM,
        })
        return
      }

      if (
        !moment(customDateRange.to, "DD/MM/YYYY", true).isValid() ||
        customDateRange.to?.length !== 10 ||
        moment(customDateRange.to, "DD/MM/YYYY").isBefore(
          user.account.dateCreated,
          "DD/MM/YYYY",
        )
      ) {
        setInvalidCustomDateRange({
          invalidRange: true,
          durationExceeded: false,
          invalidField: customDateRangeFilterFields.TO,
        })
        return
      }

      if (differenceInDays < 0) {
        setInvalidCustomDateRange({
          invalidRange: true,
          durationExceeded: false,
          invalidField: customDateRangeFilterFields.BOTH,
        })
        return
      }

      setFilteredCycle({
        label: DurationDateType.CUSTOM_RANGE,
        from: moment(customDateRange.from, "DD/MM/YYYY").format("YYYY-MM-DD"),
        to: moment(customDateRange.to, "DD/MM/YYYY").format("YYYY-MM-DD"),
      })
    } else {
      setFilteredCycle(selectedCycle)
    }

    setIsDateBottomSheetOpen(false)
  }

  const handleCloseMerchantFIlter = () => {
    setFilterMerchants(prev => ({
      ...prev,
      enabled: false,
    }))
  }

  const handleSelectMerchant = merchant => {
    if (
      filterMerchants.selectedMerchants.some(
        currMerchant => currMerchant.merchantId === merchant.merchantId,
      )
    ) {
      setFilterMerchants(prev => ({
        enabled: !prev.enabled,
        selectedMerchants: filterMerchants.selectedMerchants.filter(
          currMerchant => currMerchant.merchantId !== merchant.merchantId,
        ),
      }))
    } else {
      setFilterMerchants(prev => ({
        enabled: !prev.enabled,
        selectedMerchants: [
          ...filterMerchants.selectedMerchants,
          {
            merchantId: merchant.merchantId,
            merchantLogo: merchant.merchantLogo,
          },
        ],
      }))
    }
  }

  const handleRemoveMerchantSelected = merchant => {
    setFilterMerchants(prev => ({
      ...prev,
      selectedMerchants: filterMerchants.selectedMerchants.filter(
        currMerchant => currMerchant.merchantId !== merchant.merchantId,
      ),
    }))
  }

  return (
    <>
      <Header
        text={t("spendAnalytics.newUserSpends.spendAnalyticsText")}
        onBack={() => navigate(-1)}
      />
      <div className='v3-spend-analytics-category-insight-container'>
        <div className='v3-spend-analytics-category-insight-header'>
          <div className='v3-spend-analytics-category-insight-header-text'>
            {t("spendAnalytics.categoryInsights.categoryInsightHeader")}
          </div>
          <div className='v3-spend-analytics-category-insight-card'>
            <div className='v3-spend-analytics-category-insight-left'>
              <div className='v3-spend-analytics-category-insight-category-name'>
                {category?.groupName}{" "}
                <span>
                  <img src={categoryImage} width={14} height={14} />
                </span>
              </div>
              <div className='v3-spend-analytics-category-insight-merchants'>
                <div className='v3-spend-analytics-category-insight-merchants-logos'>
                  {merchants?.map((item, index) => {
                    const customClass = index === 0 ? "first" : "second"
                    return (
                      <img
                        src={`data:image/png;base64, ${item.merchantLogo}`}
                        className={`v3-spend-analytics-category-split-chart-2-widget-category-slide-top-part-left-category-merchants-${customClass}-logo`}
                        alt='merchant logo'
                        width={19}
                        height={19}
                        style={{ borderRadius: "100%" }}
                      />
                    )
                  })}
                </div>
                <div className='v3-spend-analytics-category-insight-merchants-count'>
                  {category?.numberOfMerchants || 0}{" "}
                  {t("spendAnalytics.categoryInsights.merchantsCountText")}
                </div>
              </div>
            </div>
            <div className='v3-spend-analytics-category-insight-right'>
              <div
                className='v3-spend-analytics-category-split-filter-tab'
                onClick={() => setIsDateBottomSheetOpen(prev => !prev)}
              >
                <div className='v3-spend-analytics-category-split-filter-tab-icon-and-text'>
                  <div className='v3-spend-analytics-category-split-filter-icon'>
                    <Calender />
                  </div>
                  <div className='v3-spend-analytics-category-split-filter-text'>
                    {t("spendAnalytics.categoryInsights.dateFilterText")}
                  </div>
                </div>
                <div className='v3-spend-analytics-category-split-filter-dropdown'>
                  <ArrowDown size={"12px"} />
                </div>
              </div>
              <div className='v3-spend-analytics-category-split-filter-selected-container'>
                {" "}
                <span className='v3-spend-analytics-category-split-filter-selected-date'>
                  {filteredCycle?.label}
                </span>{" "}
              </div>
            </div>
          </div>
          <div className='v3-spend-analytics-category-insight-reward-cashback-container'>
            <div className=''>
              {t("spendAnalytics.categoryInsights.youHaveEarned")}
            </div>{" "}
            <div>
              <Cashback width={"13px"} height={"15px"} />
            </div>
            <div className='v3-spend-analytics-category-insight-reward-cashback-amount'>
              {formatToWholeCurrency(category?.totalCashback)}
            </div>{" "}
            <div>{t("spendAnalytics.categoryInsights.cashbackText")}</div>
          </div>
        </div>
        <div className='v3-spend-analytics-category-filter-seciton'>
          <div className='v3-spend-analytics-category-filter'>
            <div className='v3-spend-analytics-category-filter-text'>
              {t("spendAnalytics.categoryInsights.sortByText")}
            </div>
            <div className='v3-spend-analytics-category-filter-dropdownicon'>
              <ArrowDown
                size={"12px"}
                color={theme.v3.rawColors.tertiaryNeutral.color1}
              />
            </div>
          </div>
          <div
            className='v3-spend-analytics-category-filter'
            onClick={() =>
              setFilterMerchants(prev => ({
                ...prev,
                enabled: true,
              }))
            }
          >
            <div className='v3-spend-analytics-category-filter-text'>
              {t("spendAnalytics.categoryInsights.merchantsCountFilter")} (
              {category?.numberOfMerchants || 0})
            </div>
            <div className='v3-spend-analytics-category-filter-dropdownicon'>
              <ArrowDown
                size={"12px"}
                color={theme.v3.rawColors.tertiaryNeutral.color1}
              />
            </div>
          </div>
          {filterMerchants.selectedMerchants?.map((item, index) => {
            return (
              <div
                className='v3-spend-analytics-category-filter'
                style={{
                  backgroundColor: theme.v3.rawColors.secondary.color4,
                  border: `1px solid ${theme.v3.cssVars.secondary.color2}`,
                  paddingLeft: "7px",
                }}
              >
                <div className='v3-spend-analytics-category-filter-text'>
                  <img
                    src={`data:image/png;base64, ${item.merchantLogo}`}
                    width={20}
                    height={20}
                  />
                </div>
                <div
                  className='v3-spend-analytics-category-filter-dropdownicon'
                  onClick={() => handleRemoveMerchantSelected(item)}
                >
                  <Cross
                    color={theme.v3.rawColors.secondary.color1}
                    height={12}
                    width={12}
                  />
                </div>
              </div>
            )
          })}
        </div>
        <div className='v3-spend-analytics-category-insight-total-txn-container'>
          <div className='v3-spend-analytics-category-insight-total-txn-count'>
            {transactionsData?.transactionCount}{" "}
            {t("spendAnalytics.categoryInsights.transactionsCountText")}
          </div>
          <div className='v3-spend-analytics-category-insight-total-txn-amount'>
            <div className='v3-spend-analytics-category-insight-total-txn-amount-text'>
              {t("spendAnalytics.categoryInsights.amountSpentText")}
            </div>
            <div className='v3-spend-analytics-category-insight-total-txn-amount-value'>
              {formatAmount(transactionsData?.totalSpent)}
            </div>
          </div>
        </div>
        <div className='v3-spend-analytics-category-insight-transactions'>
          {transactionsData?.transactions?.map(txn => {
            return <Transaction_v3 transaction={txn} />
          })}
        </div>
      </div>
      <BottomSheet open={isDateBottomSheetOpen}>
        <FilterByDate
          billingCycles={billingCycles}
          selectedCycle={selectedCycle}
          setCustomDateRange={setCustomDateRange}
          customDateRange={customDateRange}
          handleDateSelect={handleDateSelect}
          handleApplyFilter={handleApplyFilter}
          setIsBottomSheetOpen={setIsDateBottomSheetOpen}
          invalidCustomDateRange={invalidCustomDateRange}
          setInvalidCustomDateRange={setInvalidCustomDateRange}
          setDateType={setDateType}
          dateType={dateType}
          isSameState={
            selectedCycle.from == filteredCycle.from &&
            selectedCycle.to == filteredCycle.to
          }
        />
      </BottomSheet>
      <BottomSheet open={filterMerchants.enabled}>
        <MerchantFilterBottmSheet
          onClose={handleCloseMerchantFIlter}
          merchants={merchants}
          merchantSelect={merchantSelect}
          setMerchantSelect={setMerchantSelect}
          handleSelectMerchant={handleSelectMerchant}
        />
      </BottomSheet>
    </>
  )
}

export default CategoryInsight
