import React, { useState, useCallback, useEffect, useMemo } from "react"
import DividerV3 from "../../../core/Divider/v3/DividerV3"
import ArrowDown from "../../../svg/arrowDown"
import { Checkbox } from "../../../../core"
import { useSelector } from "react-redux"
import ArrowUp from "../../../svg/arrowUp"
import moment from "moment"
import Calender from "../../../svg/v3/Calender"
import { Input } from "../../../../nativeBaseReplacements"
import { ColorButton } from "../../../core/buttons"
import Error_v3 from "../../../core/Error_v3"
import InputDatePicker from "../../../core/InputDatePicker"
import { useTranslation } from "react-i18next"
import { dateFilterTypes } from "../../../../utils/constants"
import { debounceFunction } from "../../../../utils/functions"
const dateFilters = [
  {
    label: dateFilterTypes.TODAY,
    from: moment().startOf("day").format("YYYY-MM-DD"),
    to: moment().endOf("day").format("YYYY-MM-DD"),
  },
  {
    label: dateFilterTypes.THIS_WEEK,
    from: moment().startOf("week").format("YYYY-MM-DD"),
    to: moment().endOf("week").format("YYYY-MM-DD"),
  },
  {
    label: dateFilterTypes.THIS_MONTH,
    from: moment().startOf("month").format("YYYY-MM-DD"),
    to: moment().endOf("month").format("YYYY-MM-DD"),
  },
  {
    label: dateFilterTypes.CUSTOM_RANGE,
    from: null,
    to: null,
  },
]

const transactionTypes = [
  {
    label: "Purchases",
    value: "PURCHASE",
  },
  {
    label: "Repayments",
    value: "REPAYMENT",
  },
  {
    label: "Refunds",
    value: "REFUND",
  },
]

const TransactionFilters = ({
  dropDownSelected,
  setDropDownSelected,
  selectTransationType,
  setSelectedTransactionType,
  setFilterList,
  filterList,
  customDateRange,
  setCustomDateRange,
  setInvalidCustomDateRange,
  invalidCustomDateRange,
}) => {
  const { t } = useTranslation()
  const theme = useSelector(state => state.theme)
  const [isFilterByDateEnabled, setIsFilterByDateEnabled] = useState(true)
  const [isFilterByTxnTypeEnabled, setIsFilterByTxnTypeEnabled] = useState(true)

  const handleDropDownClick = () => {
    if (dropDownSelected.isEnabled) {
      setDropDownSelected(prev => {
        return {
          ...prev,
          isEnabled: false,
        }
      })
    } else {
      setDropDownSelected(prev => {
        return {
          ...prev,
          isEnabled: true,
        }
      })
    }
  }

  const handleSelectFilter = filter => {
    setDropDownSelected({
      isEnabled: false,
      from: filter?.from,
      to: filter?.to,
      value: filter?.label,
    })
    setCustomDateRange(prev => ({
      ...prev,
      from: "",
      to: "",
    }))

    if (filter.label !== dateFilterTypes.CUSTOM_RANGE) {
      if (filterList.includes(filter?.label)) {
        setFilterList(prev => {
          return prev?.filter(label => label !== filter?.label)
        })
      } else {
        setFilterList(prev => [...prev, filter?.label])
      }
    }
  }

  const handleFilterByDateDropDownClick = () => {
    setIsFilterByDateEnabled(prev => !prev)
  }

  const handleTransactionTypeClick = filterObj => {
    if (selectTransationType?.value.includes(filterObj?.value)) {
      setSelectedTransactionType(prev => ({
        ...prev,
        value: prev.value.filter(val => val !== filterObj.value),
      }))
    } else {
      setSelectedTransactionType(prev => ({
        ...prev,
        value: [...prev.value, filterObj.value],
      }))
    }
    if (filterList.includes(filterObj?.label)) {
      setFilterList(prev => {
        return prev?.filter(label => label !== filterObj?.label)
      })
    } else {
      setFilterList(prev => [...prev, filterObj?.label])
    }
  }

  const setFromDate = useCallback(
    date => {
      setInvalidCustomDateRange({
        invalidRange: false,
        durationExceeded: false,
      })
      setCustomDateRange(prev => ({
        ...prev,
        from: date,
      }))
    },
    [customDateRange?.from],
  )

  const setToDate = useCallback(
    date => {
      setInvalidCustomDateRange({
        invalidRange: false,
        durationExceeded: false,
      })
      setCustomDateRange(prev => ({
        ...prev,
        to: date,
      }))
    },
    [customDateRange?.to],
  )

  const debouncedValidateRange = useMemo(
    () =>
      debounceFunction((from, to) => {
        if (!from || moment(to).isBefore(from)) {
          return
        }

        const numberOfDays = moment(to).diff(moment(from), "days")
        if (numberOfDays > 90) {
          setInvalidCustomDateRange(prev => ({
            ...prev,
            durationExceeded: true,
          }))
          return
        }

        setDropDownSelected({
          value: dateFilterTypes.CUSTOM_RANGE,
          from,
          to,
        })

        if (filterList.includes(dateFilterTypes.CUSTOM_RANGE)) {
          setFilterList(prev =>
            prev?.filter(label => label !== dateFilterTypes.CUSTOM_RANGE),
          )
        } else {
          setFilterList(prev => [...prev, dateFilterTypes.CUSTOM_RANGE])
        }
      }, 700),
    [],
  )

  useEffect(() => {
    if (customDateRange.to && customDateRange.from) {
      debouncedValidateRange(customDateRange.from, customDateRange.to)
    }
  }, [customDateRange.from, customDateRange.to])

  const renderErrorContainer = () => {
    if (invalidCustomDateRange?.invalidRange) {
      return (
        <Error_v3
          errorMessage={t(
            "payLaterWebComponents.transactionFilters.invalidDate",
          )}
          customClassName={"fkw-web-filter"}
        />
      )
    } else if (invalidCustomDateRange?.durationExceeded) {
      return (
        <Error_v3
          errorMessage={t(
            "payLaterWebComponents.transactionFilters.invalidDateRange",
          )}
          customClassName={"customerAuth"}
        />
      )
    }
  }

  const handleTxnTypeDropDownClick = () => {
    setIsFilterByTxnTypeEnabled(prev => !prev)
  }

  return (
    <div className='v3-web-all-transactions-filter-container'>
      <div className='v3-web-all-transactions-filter-parent-container'>
        <div className='v3-web-all-transactions-filter-header'>
          <div className='v3-web-all-transactions-filter-header-text'>
            {t("payLaterWebComponents.transactionFilters.filterByDate")}
          </div>
          <div
            className='v3-web-all-transactions-filter-header-icon'
            onClick={handleFilterByDateDropDownClick}
          >
            {isFilterByDateEnabled ? (
              <ArrowDown
                size={"11px"}
                color={theme.v3.rawColors.tertiaryNeutral.color2}
              />
            ) : (
              <ArrowUp
                size={"11px"}
                color={theme.v3.rawColors.tertiaryNeutral.color2}
              />
            )}
          </div>
        </div>
        {isFilterByDateEnabled && (
          <>
            <div className='v3-web-all-transactions-date-range-container'>
              <div className='v3-web-all-transactions-date-range-header'>
                {t("payLaterWebComponents.transactionFilters.dateRange")}
              </div>
              <div className='v3-web-all-transactions-date-select-container'>
                <div className='v3-web-all-transactions-date-selector'>
                  <div className='v3-web-all-transactions-date-selector-month-name'>
                    {dropDownSelected.value}
                  </div>
                  <div
                    className='v3-web-all-transactions-date-selector-icon'
                    onClick={handleDropDownClick}
                  >
                    <ArrowDown
                      size={"7px"}
                      color={theme.v3.rawColors.tertiaryNeutral.color2}
                    />
                  </div>
                </div>
              </div>
            </div>
            {dropDownSelected.value === dateFilterTypes.CUSTOM_RANGE && (
              <div className='v3-web-all-transactions-custom-date-select-container'>
                <div className='v3-web-all-transactions-custom-date-select-from-container'>
                  <div className='v3-web-all-transactions-custom-range-from-container'>
                    <div className='v3-custom-range-from-label'>
                      <div className='v3-custom-range-date-icon'>
                        <Calender color='#2680EA' width={"12"} height={"12"} />
                      </div>
                      <div className='v3-custom-range-date-text v3-web-all-transactions-range-date-text'>
                        {t("payLaterWebComponents.transactionFilters.fromText")}
                      </div>
                    </div>
                    <div className='v3-custom-range-from-input'>
                      <InputDatePicker
                        date={customDateRange?.from}
                        setDate={setFromDate}
                        dateType={"date"}
                        maxDate={moment(new Date()).format("YYYY-MM-DD")}
                      />
                    </div>
                  </div>
                </div>
                <div className='v3-web-all-transactions-custom-date-select-to-container'>
                  <div className='v3-web-all-transactions-custom-range-from-container'>
                    <div className='v3-custom-range-from-label'>
                      <div className='v3-custom-range-date-icon'>
                        <Calender color='#2680EA' width={"12"} height={"12"} />
                      </div>
                      <div className='v3-custom-range-date-text v3-web-all-transactions-range-date-text'>
                        {t("payLaterWebComponents.transactionFilters.toText")}
                      </div>
                    </div>
                    <div className='v3-custom-range-from-input'>
                      <InputDatePicker
                        date={customDateRange?.to}
                        setDate={setToDate}
                        dateType={"date"}
                        minDate={customDateRange.from}
                        maxDate={moment(new Date()).format("YYYY-MM-DD")}
                      />
                    </div>
                  </div>
                </div>
                <div className='v3-web-custom-range-error-container'>
                  {renderErrorContainer()}
                </div>
              </div>
            )}
          </>
        )}
        {dropDownSelected.isEnabled && (
          <div
            className='v3-web-all-transactions-dropdown-container'
            style={{
              top:
                dropDownSelected.value === dateFilterTypes.CUSTOM_RANGE &&
                "42%",
            }}
          >
            {dateFilters.map((filter, index) => {
              return (
                <>
                  <div
                    className='v3-web-all-transactions-dropdown-filter'
                    onClick={() => handleSelectFilter(filter)}
                    style={{
                      color:
                        dropDownSelected.value === filter?.label &&
                        theme.v3.cssVars.secondary.color1,
                    }}
                  >
                    {filter?.label}
                  </div>
                  {index !== dateFilters.length - 1 && (
                    <DividerV3 marginBottom={"0"} />
                  )}
                </>
              )
            })}
          </div>
        )}
      </div>
      <DividerV3
        width={"100%"}
        marginBottom={"0px"}
        color={theme.v3.rawColors.primaryBase.color5}
      />
      <div className='v3-web-all-transactions-checkbox-container'>
        <div className='v3-web-all-transactions-checkbox-header'>
          <div className='v3-web-all-transactions-checkbox-header-text'>
            {t(
              "payLaterWebComponents.transactionFilters.showTransactionsInvolving",
            )}
          </div>
          <div
            className='v3-web-all-transactions-checkbox-dropdown-icon'
            onClick={handleTxnTypeDropDownClick}
          >
            {isFilterByTxnTypeEnabled ? (
              <ArrowDown
                size={"11px"}
                color={theme.v3.rawColors.tertiaryNeutral.color2}
              />
            ) : (
              <ArrowUp
                size={"11px"}
                color={theme.v3.rawColors.tertiaryNeutral.color2}
              />
            )}
          </div>
        </div>
        <div
          className='v3-web-all-transactions-transacitontype-list-container'
          style={{ display: isFilterByTxnTypeEnabled ? "none" : "block" }}
        >
          {transactionTypes.map((filter, index) => {
            const isTncChecked = selectTransationType.value?.includes(
              filter.value,
            )
            return (
              <div className='v3-web-all-transactions-transactiontype-list'>
                <div
                  className='v3-web-all-transactions-transactiontype-checkbox'
                  onClick={() => handleTransactionTypeClick(filter)}
                >
                  <Checkbox
                    checkmarkClassName={
                      isTncChecked
                        ? "setMpin-tnc-checkMark-checked"
                        : "setMpin-tnc-checkMark-notchecked"
                    }
                    isChecked={isTncChecked}
                    backgroundColor={
                      isTncChecked ? theme.v3.rawColors.secondary.color1 : null
                    }
                  />
                </div>
                <div className='v3-web-all-transactions-transactiontype-label'>
                  {filter.label}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default TransactionFilters
