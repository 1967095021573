import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import TransactionFilters from "./TransactionFilters"
import Sidebar from "./Sidebar"
import { useLocation } from "react-router-dom"
import moment from "moment"
import WebTransactionList from "./WebTransactionList"
import WebViewHeader from "./WebViewHeader"
import { dateFilterTypes } from "../../../../utils/constants"

const dateFilters = [
  {
    label: dateFilterTypes.TODAY,
    from: moment().startOf("day").format("YYYY-MM-DD"),
    to: moment().endOf("day").format("YYYY-MM-DD"),
  },
  {
    label: dateFilterTypes.THIS_WEEK,
    from: moment().startOf("week").format("YYYY-MM-DD"),
    to: moment().endOf("week").format("YYYY-MM-DD"),
  },
  {
    label: dateFilterTypes.THIS_MONTH,
    from: moment().startOf("month").format("YYYY-MM-DD"),
    to: moment().endOf("month").format("YYYY-MM-DD"),
  },
  {
    label: dateFilterTypes.CUSTOM_RANGE,
    from: null,
    to: null,
  },
]

const AllTransactions = () => {
  const isTncChecked = false
  const [selectedTransaction, setSelectedTransaction] = useState()
  const [customDateRange, setCustomDateRange] = useState({
    from: null,
    to: null,
  })
  const { state } = useLocation()
  const [selectTransationType, setSelectedTransactionType] = useState({
    isEnabled: true,
    value: state?.transactionFilter ? [state?.transactionFilter] : [],
  })
  const [dropDownSelected, setDropDownSelected] = useState({
    value: dateFilters[2].label,
    from: dateFilters[2].from,
    to: dateFilters[2].to,
    isEnabled: false,
  })
  const [filterList, setFilterList] = useState([])
  const [invalidCustomDateRange, setInvalidCustomDateRange] = useState({
    invalidRange: false,
    durationExceeded: false,
  })

  const handleCloseSidebar = () => {
    setSelectedTransaction(null)
  }

  return (
    <>
      <WebViewHeader headerText={"All Transactions"} />
      <div className='v3-web-all-transactions-section'>
        <div className='v3-web-all-transactions-container'>
          <TransactionFilters
            isTncChecked={isTncChecked}
            selectTransationType={selectTransationType}
            setSelectedTransactionType={setSelectedTransactionType}
            dropDownSelected={dropDownSelected}
            setDropDownSelected={setDropDownSelected}
            setFilterList={setFilterList}
            filterList={filterList}
            customDateRange={customDateRange}
            setCustomDateRange={setCustomDateRange}
            setInvalidCustomDateRange={setInvalidCustomDateRange}
            invalidCustomDateRange={invalidCustomDateRange}
          />
          <div className='v3-web-all-transactions-transaction-table-container'>
            <WebTransactionList
              state={state}
              selectTransationType={selectTransationType}
              dropDownSelected={dropDownSelected}
              filterList={filterList}
              setSelectedTransaction={setSelectedTransaction}
            />
          </div>
        </div>
      </div>
      {selectedTransaction && (
        <>
          <div
            className='v3-web-all-transactions-sidebar-backdrop'
            style={{
              height: "100vh",
              width: "100vw",
              backgroundColor: "black",
              opacity: "0.8",
              position: "absolute",
            }}
          ></div>
          <Sidebar
            data={selectedTransaction}
            onClose={handleCloseSidebar}
            currentPage={"ALL_TRANSACTIONS"}
          />
        </>
      )}
    </>
  )
}

export default AllTransactions
