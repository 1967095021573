import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import DocumentList from "../../../svg/v3/DocumentList"
import ClockBig from "../../../svg/v3/ClockBig"
import QuickActionsItemWithDescription from "./QuickActionsItemWithDescription.js"
import QuickActionsItem from "./QuickActionsItem.js"
import ArrowUp from "../../../svg/arrowUp.js"
import ArrowDown from "../../../svg/arrowDown.js"
import EmiIcon from "../../../svg/emiIcon.js"
import AnalyseSpends from "../../../svg/analyseSpends.js"
import useWindowDimensions from "../../../../hooks/useWindowDimensionsWeb.js"
import { ProgramTypes, QuickActionsTypes } from "../../../../utils/constants.js"
import { RepaymentHistory } from "../../../svg/v3/RepaymentHistory.js"
import usePaylaterDesktopViewEnabled from "../../../../hooks/usePaylaterDesktopViewEnabled.js"

const QuickActions = ({ quickActions }) => {
  const { t } = useTranslation()
  const [theme] = useSelector(state => [state.theme])
  const user = useSelector(state => state.user)
  const [filteredQuickActions, setFilteredQuickActions] = useState([])
  const [showMore, setShowMore] = useState(false)
  const windowDimensions = useWindowDimensions()
  const activitySectionRef = useRef()
  const isWebViewEnabled = usePaylaterDesktopViewEnabled()

  useEffect(() => {
    if (isWebViewEnabled) {
      activitySectionRef.current?.classList.add("v3-web-activity-section")
    } else {
      activitySectionRef.current?.classList.remove("v3-web-activity-section") // To remove the class when the condition is not met
    }
  }, [windowDimensions])

  const {
    primary: { color1: primaryColor1 },
    secondary: { color1: secondarColor1 },
    tertiaryNeutral: { color1: tertiaryNeutralColor1 },
  } = theme.v3.rawColors

  const getQuickActionHeader = type => {
    let responseObject = {}
    switch (type) {
      case QuickActionsTypes.PROGRAM_DETAILS: {
        responseObject[QuickActionsTypes.PROGRAM_DETAILS] = {
          text1: t("payLaterWebComponents.quickActions.programDetails"),
          text2: "",
          redirectTo: "/programDetails",
          icon: <DocumentList color={primaryColor1} />,
        }
      }
      case QuickActionsTypes.STATEMENT: {
        responseObject[QuickActionsTypes.STATEMENT] = {
          text1: t("ActivitySectionv3.view"),
          text2: t("ActivitySectionv3.statement"),
          redirectTo: "/statement",
          icon: <DocumentList color={primaryColor1} />,
        }
      }
      case QuickActionsTypes.REPAYMENT_HISTORY: {
        responseObject[QuickActionsTypes.REPAYMENT_HISTORY] = {
          text1: t("payLaterWebComponents.quickActions.repaymentHistory"),
          text2: "",
          redirectTo: "/transactions",
          icon: <RepaymentHistory color={primaryColor1} />,
        }
      }
      case QuickActionsTypes.HISTORY: {
        responseObject[QuickActionsTypes.HISTORY] = {
          text1: t("ActivitySectionv3.payment"),
          text2: t("ActivitySectionv3.history"),
          redirectTo: "/transactions",
          icon: <ClockBig color={primaryColor1} />,
        }
      }
      case QuickActionsTypes.MY_REFUNDS: {
        responseObject[QuickActionsTypes.MY_REFUNDS] = {
          text1: t("payLaterWebComponents.quickActions.myRefunds"),
          redirectTo: "/transactions",
          icon: <AnalyseSpends color={primaryColor1} />,
        }
      }
      case QuickActionsTypes.ANALYSE_SPENDS: {
        responseObject[QuickActionsTypes.ANALYSE_SPENDS] = {
          text1: "Analyse Spends",
          redirectTo: "/spendAnalytics",
          icon: <AnalyseSpends color={primaryColor1} />,
        }
      }
    }
    return responseObject
  }
  const quickActionsConfig = {
    STATEMENT: {
      actionType: QuickActionsTypes.STATEMENT,
      text1: getQuickActionHeader(QuickActionsTypes.STATEMENT).STATEMENT.text1,
      text2: getQuickActionHeader(QuickActionsTypes.STATEMENT).STATEMENT.text2,
      description: "Get smart statement summary",
      redirectTo: getQuickActionHeader(QuickActionsTypes.STATEMENT).STATEMENT
        .redirectTo,
      icon: getQuickActionHeader(QuickActionsTypes.STATEMENT).STATEMENT.icon,
    },
    HISTORY: {
      text1: getQuickActionHeader(QuickActionsTypes.HISTORY).HISTORY.text1,
      text2: getQuickActionHeader(QuickActionsTypes.HISTORY).HISTORY.text2,
      redirectTo: getQuickActionHeader(QuickActionsTypes.HISTORY).HISTORY
        .redirectTo,
      icon: getQuickActionHeader(QuickActionsTypes.HISTORY).HISTORY.icon,
    },
    ANALYSIS: {
      text1: getQuickActionHeader(QuickActionsTypes.ANALYSE_SPENDS)
        .ANALYSE_SPENDS.text1,
      icon: getQuickActionHeader(QuickActionsTypes.ANALYSE_SPENDS)
        .ANALYSE_SPENDS.icon,
      redirectTo: getQuickActionHeader(QuickActionsTypes.ANALYSE_SPENDS)
        .ANALYSE_SPENDS.redirectTo,
    },
    PROGRAM_DETAILS: {
      text1: getQuickActionHeader(QuickActionsTypes.PROGRAM_DETAILS)
        .PROGRAM_DETAILS.text1,
      text2: getQuickActionHeader(QuickActionsTypes.PROGRAM_DETAILS)
        .PROGRAM_DETAILS.text2,
      redirectTo: getQuickActionHeader(QuickActionsTypes.PROGRAM_DETAILS)
        .PROGRAM_DETAILS.redirectTo,
      icon: getQuickActionHeader(QuickActionsTypes.PROGRAM_DETAILS)
        .PROGRAM_DETAILS.icon,
    },
    REPAYMENT_HISTORY: {
      text1: getQuickActionHeader(QuickActionsTypes.REPAYMENT_HISTORY)
        .REPAYMENT_HISTORY.text1,
      text2: getQuickActionHeader(QuickActionsTypes.REPAYMENT_HISTORY)
        .REPAYMENT_HISTORY.text2,
      redirectTo: getQuickActionHeader(QuickActionsTypes.REPAYMENT_HISTORY)
        .REPAYMENT_HISTORY.redirectTo,
      transactionFilter: "REPAYMENT",
      icon: getQuickActionHeader(QuickActionsTypes.REPAYMENT_HISTORY)
        .REPAYMENT_HISTORY.icon,
    },
    MY_REFUNDS: {
      text1: getQuickActionHeader(QuickActionsTypes.MY_REFUNDS).MY_REFUNDS
        .text1,
      text2: getQuickActionHeader(QuickActionsTypes.MY_REFUNDS).MY_REFUNDS
        .text2,
      redirectTo: getQuickActionHeader(QuickActionsTypes.MY_REFUNDS).MY_REFUNDS
        .redirectTo,
      transactionFilter: "REFUND",
      icon: getQuickActionHeader(QuickActionsTypes.MY_REFUNDS).MY_REFUNDS.icon,
    },
    EMI: {
      text1: "Your EMIs",
      icon: <EmiIcon color={primaryColor1} />,
    },
  }

  useEffect(() => {
    setFilteredQuickActions([
      ...quickActions?.filter(action => {
        return quickActionsConfig[action]
      }),
    ])
  }, [quickActions])

  const getVisibleQuickActions = () => {
    if (showMore) {
      return filteredQuickActions
    } else {
      return filteredQuickActions.slice(0, 3)
    }
  }

  return (
    filteredQuickActions?.length > 0 && (
      <div
        ref={activitySectionRef}
        className='v3-activity-section-actions'
        style={{
          backgroundColor: isWebViewEnabled && theme.v3.cssVars.leadingWhite,
          borderRadius: isWebViewEnabled && "21px",
          boxShadow: isWebViewEnabled && "0px 10px 31px -2px #00000017",
        }}
      >
        {filteredQuickActions?.length > 1 && (
          <div className='v3-activity-section-header'>
            <div className='v3-activity-section-header-text'>
              {t("ActivitySectionv3.quickActions")}
            </div>
            {filteredQuickActions?.length > 3 &&
              windowDimensions.width < 500 && (
                <div
                  className='v3-activity-section-header-toggle-button'
                  onClick={() => setShowMore(!showMore)}
                >
                  <div
                    className={
                      showMore
                        ? "v3-activity-section-header-toggle-open"
                        : "v3-activity-section-header-toggle-closed"
                    }
                  >
                    {showMore ? "Show Less" : "Show All"}
                  </div>
                  {showMore ? (
                    <ArrowUp color={tertiaryNeutralColor1} size={"8px"} />
                  ) : (
                    <ArrowDown color={secondarColor1} size={"8px"} />
                  )}
                </div>
              )}
          </div>
        )}
        <div className='v3-activity-section-actions-body'>
          {filteredQuickActions?.length === 1 && (
            <QuickActionsItemWithDescription
              actionConfig={quickActionsConfig[filteredQuickActions[0]]}
              isOnly
            />
          )}
          {filteredQuickActions?.length === 2 &&
            filteredQuickActions?.map((action, index) => {
              return (
                <QuickActionsItemWithDescription
                  key={index}
                  actionConfig={quickActionsConfig[action]}
                />
              )
            })}
          {filteredQuickActions?.length > 2 && filteredQuickActions?.length < 4
            ? filteredQuickActions?.map((action, index) => {
                return (
                  <QuickActionsItem
                    key={index}
                    actionConfig={quickActionsConfig[action]}
                  />
                )
              })
            : null}
          {filteredQuickActions?.length >= 4 && (
            <div className='v3-activity-section-actions-grid'>
              {getVisibleQuickActions()?.map((action, index) => {
                return (
                  <QuickActionsItem
                    key={index}
                    actionConfig={quickActionsConfig[action]}
                  />
                )
              })}
            </div>
          )}
        </div>
      </div>
    )
  )
}

export default QuickActions
